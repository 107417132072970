import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const languages = ["FR", "EN"];

export const LanguageSelector = ({ selectedLanguage, setSelectedLanguage }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if(!lang) localStorage.setItem("language", "fr");	  
  }, [])

  const changeLanguage = (event) => {
    const lang = event.target.value.toLowerCase();
    setSelectedLanguage(lang);
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  };

  return (
    <select
      className="border-none bg-transparent focus:ring-0 text-[18px] font-bold text-primary-green"
      onChange={(e) => changeLanguage(e)}
      value={selectedLanguage}
    >
      {languages.map((lang, i) => (
        <option key={i} id={`${lang}-${i}`} value={lang.toLowerCase()}>
          {lang.toUpperCase()}
        </option>
      ))}
    </select>
  );
};
