import { Link } from "@tanstack/react-router";

import { useGlobalContext } from "../providers/GlobalProviders";

import Instagram from "/src/assets/svgs/instagram.svg?react";
import Facebook from "/src/assets/svgs/facebook.svg?react";

export const Footer = () => {
  const { t } = useGlobalContext();

  return (
    <footer className="relative flex md:h-[525px] h-fit bg-primary-green text-white md:justify-between justify-center xl:px-24 items-center flex-col xl:flex-row md:gap-5 gap-10 py-14">
      <img
        src="/logos/footer.png"
        alt="galaxia_secondary_logo"
        className="screen-1:w-[368px] screen-1:h-[230px] md:w-[300px] md:h-[170px] xl:absolute xl:left-1/2 transform xl:-translate-x-1/2 w-[191px] md:m-0 mx-auto"
      />
      <div className="xl:order-last">
        <span className="font-bold md:text-[20px] text-left">{t("Suivez-nous")}</span>
        <div className="flex gap-4 items-center justify-center">
          <Link to="https://www.facebook.com/chaletsgalaxia/" target="_blank" rel="noreferrer"><Facebook className="size-[31px]" /></Link>
          <Link to="https://www.instagram.com/chaletsgalaxia/" target="_blank" rel="noreferrer"><Instagram className="size-[31px]" /></Link>
        </div>
      </div>
      <div className="flex md:flex-row flex-col screen-1:gap-36 gap-10 ">
        <div className="flex flex-col md:gap-5 md:items-start items-center">
          <span className="font-bold md:text-[20px] md:mb-0 mb-2">
            {t("Liens rapides")}
          </span>
          <Link
            to="/search"
            className="font-light md:text-[20px] hover:underline"
          >
            {t("Louer un chalet")}
          </Link>
          <Link
            to="/services"
            className="font-light md:text-[20px] hover:underline"
          >
            {t("Gestion locative")}
          </Link>
          <Link
            to="/partners"
            className="font-light md:text-[20px] hover:underline"
          >
            {t("Partenaires")}
          </Link>
        </div>
        <div className="flex flex-col md:gap-5 md:items-start items-center">
          <span className="font-bold md:text-[20px] md:mb-0 mb-2">
            {t("Liens rapides")}
          </span>
          <Link
            to="/contact#team"
            className="font-light md:text-[20px] hover:underline"
          >
            {t("Qui nous sommes")}
          </Link>
          <Link
            to="/faqs"
            className="font-light md:text-[20px] hover:underline"
          >
            {t("FAQ")}
          </Link>
          <Link
            to="/contact"
            className="font-light md:text-[20px] hover:underline"
          >
            {t("Contactez-nous")}
          </Link>
        </div>
      </div>
    </footer>
  );
};
