import { useState, useEffect } from "react";
import { Navbar } from "flowbite-react";
import { Link } from "@tanstack/react-router";

import { useGlobalContext } from "../providers/GlobalProviders";

import { LanguageSelector } from "../components/LanguageSelector";

import Calendar from "/src/assets/svgs/calendar.svg?react";

export const NavBar = ({ selectedLanguage, setSelectedLanguage }) => {
  const { t } = useGlobalContext();
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    setDisplay(window.innerWidth > 1280);

    const handleResize = () => {
      setDisplay(window.innerWidth > 1280);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const GestionLocativeButton = () => (
    <Link
      className="rounded-[21px] font-bold text-white bg-primary-green flex items-center justify-center h-[45px] w-[200px] text-[18px]"
      to="/services"
    >
      {t("Gestion locative")}
    </Link>
  );

  const ReserverChaletButton = () => (
    <Link
      className="rounded-[21px] font-bold text-white bg-primary-green flex items-center justify-center h-[45px] w-[231px] text-[18px] gap-2"
      to="/search"
    >
	<Calendar className="text-white w-[17px] h-[17px] stroke-[15px]" />
      {t("Réserver un chalet")}
    </Link>
  );

  const TitleBrand = () => (
    <Navbar.Brand href="/">
      <img
        src="/logos/main.png"
        alt="galaxia_primary_logo"
        className="w-[229px] h-[35px]"
      />
    </Navbar.Brand>
  );

  return (
    <nav className="fixed top-0 left-0 w-full z-50">
        <div className="flex flex-col bg-white" id="navbar">
          <div className="flex bg-tertiary-green h-5">
          </div>
          <div className="flex justify-between items-center flex-row p-5">
            {display ? <GestionLocativeButton /> : null}
            {display ? (
              <div className="flex flex-1 items-center">
                <div className="screen-1:absolute screen-1:left-1/2 transform screen-1:-translate-x-1/2 -translate-x-[-5%]">
                  <Link to="/" className="text-4xl text-primary-green">
                    <img
                      src="/logos/main.png"
                      alt="galaxia_primary_logo"
                      className="w-[368px] h-[57px]"
                    />
                  </Link>
                </div>
              </div>
            ) : null}
            <Navbar fluid rounded>
              {!display ? <TitleBrand /> : null}
              <Navbar.Collapse>
                <Navbar.Link
                  href="/search"
                  className="text-[18px] font-bold text-primary-green"
                >
                  {t("Recherche")}
                </Navbar.Link>
                <Navbar.Link
                  href="/promos"
                  className="text-[18px] font-bold text-primary-green"
                >
                  {t("Promos")}
                </Navbar.Link>
                <Navbar.Link
                  href="/contact"
                  className="text-[18px] font-bold text-primary-green"
                >
                  {t("Contact")}
                </Navbar.Link>
                {!display ? <Navbar.Link
      className="rounded-[21px] font-bold text-white bg-primary-green flex items-center justify-center h-[45px] w-[200px] text-[18px]"
      href="/services"
    >
      {t("Gestion locative")}
    </Navbar.Link> : null}
                {!display ? <Navbar.Link
      className="rounded-[21px] font-bold text-white bg-primary-green flex items-center justify-center h-[45px] w-[231px] text-[18px] gap-2"
      href="/search"
    >
        <Calendar className="text-white w-[17px] h-[17px] stroke-[15px]" />
      {t("Réserver un chalet")}
    </Navbar.Link> : null}
              </Navbar.Collapse>
              <div className="flex order-2 xl:order-last md:ml-4 md:mr-4">
                <LanguageSelector
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                />
                <Navbar.Toggle />
              </div>
            </Navbar>
            {display ? <ReserverChaletButton /> : null}
          </div>
        </div>
    </nav>
  );
};
