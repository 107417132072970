import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { createContext, useContext, useEffect, useState } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [display, setDisplay] = useState(true);
  const budgetData = [
    {
      value: "100-200",
      name: "100$-200$",
    },
    {
      value: "200-500",
      name: "200$-500$",
    },
  ];
  const customFilters = [
    {
      value: "Pets allowed",
      label: t("Pets allowed"),
    },
    {
      value: "Spa",
      label: t("SPA"),
    },
    {
      value: "Sauna",
      label: t("Sauna"),
    },
    {
      value: "Pool table",
      label: t("Billard"),
    },
    {
      value: "Air conditioning",
      label: t("Air conditioning"),
    },
    {
      value: "Waterfront",
      label: t("Waterfront"),
    },
    {
      value: "Ski in/Ski out",
      label: t("Ski in/Ski out"),
    },
    {
      value: "Swimming pool",
      label: t("Swimming pool"),
    },
    {
      value: "Long term stays allowed",
      label: t("Long term stays allowed"),
    },
    {
      value: "Indoor fireplace",
      label: t("Indoor fireplace"),
    },
    {
      value: "Internet",
      label: t("Internet"),
    },
    {
      value: "Lake access",
      label: t("Lake access"),
    },
    {
      value: "BBQ grill",
      label: t("BBQ grill"),
    },
    {
      value: "EV charger",
      label: t("EV charger"),
    },
  ];

  useEffect(() => {
    setDisplay(window.innerWidth > 768);

    const handleResize = () => {
      setDisplay(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const globalVariables = {
    t,
    navigate,
    display,
    budgetData,
    customFilters,
  };

  return (
    <GlobalContext.Provider value={globalVariables}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
