import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  createRootRoute,
  Outlet,
  ScrollRestoration,
} from "@tanstack/react-router";
import { Flowbite } from "flowbite-react";

import { GlobalProvider } from "../providers/GlobalProviders";

import { NavBar } from "../macrocomponents/NavBar";
import { Footer } from "../macrocomponents/Footer";

export const Route = createRootRoute({
  component: Root,
});

const theme = {
  navbar: {
    root: {
      base: "",
      inner: {
        base: "flex flex-wrap items-center justify-between w-screen xl:w-full",
        fluid: {
          on: "",
          off: "container",
        },
      },
    },
    collapse: {
      base: "w-full xl:block xl:w-auto xl:flex xl:flex-col relative",
      list: "flex flex-col xl:mt-0 xl:flex-row xl:text-sm xl:font-medium items-center justify-end gap-6 xl:my-0 my-10 xl:mr-0 mr-10",
      hidden: {
        on: "hidden",
        off: "order-last",
      },
    },
    link: {
      base: "",
      active: {
        on: "",
        off: "",
      },
    },
    toggle: {
      base: "pr-10 inline-flex items-center text-sm text-primary-green focus:outline-none focus:ring-0 xl:hidden",
      icon: "h-6 w-6 shrink-0",
    },
  },
  accordion: {
    root: {
      base: "border-none bg-transparent md:max-w-[915px]",
    },
    content: {
      base: "md:p-5 w-full md:text-[20px] text-[16px] text-justify text-primary-green",
    },
    title: {
      arrow: {
        base: "md:h-4 h-3 md:w-4 w-3 shrink-0",
        open: {
          off: "",
          on: "rotate-180",
        },
      },
      base: "flex items-center justify-start md:gap-20 gap-5 md:px-5 md:py-5 py-3 text-left text-primary-green",
      heading: "font-bold md:text-[25px] text-[20px]",
      open: {
        off: "hover:bg-transparent focus:ring-transparent",
        on: "bg-transparent hover:bg-transparent focus:ring-transparent",
      },
    },
  },
};

function Root() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <Flowbite theme={{ theme: theme }}>
	  <GlobalProvider>
      <div className="h-screen">
        <NavBar
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
        <Outlet />
	<ScrollRestoration />
        <Footer />
      </div>
    </GlobalProvider>
	  </Flowbite>
  );
}
