import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./locales/en/translationV1.json";
import frTranslations from "./locales/fr/translationV1.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    fr: {
      translation: frTranslations,
    },
  },
  lng: "fr", // initial language set to French
  fallbackLng: "fr", // use French if the selected language translation is not available
});

export default i18n;
